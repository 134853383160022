import '@/plugins/vue-composition-api'
import '@/styles/styles.scss'
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import store from './store'
import $ from 'jquery';
import DatetimePicker from 'vuetify-datetime-picker'
import './assets/css/animate.css';
import './assets/css/style.css';
import './assets/css/style_rwd.css';

Vue.config.productionTip = false
Vue.use(DatetimePicker);
new Vue({
  router,
  store,
  vuetify,
  render: h => h(App),
}).$mount('#app')
