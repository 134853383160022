export default {
    // 這個方法比較麻煩, 改路徑要重新build, 應改用.env方法
    API_src :{ //原始API
        api0 : "https://api1.com/api?a={p0}&b={p1}&c={p2}",
        //台中空氣品質 : {}
        realtime : "https://www.taichuangaiot.org/env/realtime?token=eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpZCI6ImdvdmVybm1lbnQiLCJkYXRlIjoiMjAyMS8zLzMxIOS4i-WNiDk6NTI6MjMiLCJpYXQiOjE2MTcxOTg3NDN9.c6iAXjGnpZw_rHMhc3hHRqNq2VTZiM-LpbpSGQMPaDM", 
        //AQI : {limit:1000}
        aqx_p_432 : "https://data.epa.gov.tw/api/v1/aqx_p_432?limit={limit}&api_key=9be7b239-557b-4c10-9775-78cadfc555e9&format=json", 
        //紫外線 : {limit:1000}
        uv_s_01 : "https://data.epa.gov.tw/api/v1/uv_s_01?limit={limit}&api_key=9be7b239-557b-4c10-9775-78cadfc555e9&format=json" 
    },
    API_iisx :{ //localhost api
        //台中空氣品質 : {}
        realtime : "https://localhost:44358/api/realtime", 
        //AQI : {limit:1000}
        aqx_p_432 : "https://localhost:44358/api/aqx_p_432?limit={limit}", 
        //紫外線 : {limit:1000}
        uv_s_01 : "https://localhost:44358/api/ApiUV?limit={limit}" 
    },
    API_demo :{ //校稿區 api
        //台中空氣品質 : {}
        realtime : "https://lab6.eztrust.tw/api/realtime", 
        //AQI : {limit:1000}
        aqx_p_432 : "https://lab6.eztrust.tw/api/aqx_p_432?limit={limit}", 
        //紫外線 : {limit:1000}
        uv_s_01 : "https://lab6.eztrust.tw/api/ApiUV?limit={limit}",
        //測站 api (行政區、路名)
        station : "https://lab6.eztrust.tw/api/station",
        //機器 + 時間
        records : "https://lab6.eztrust.tw/api/records?station={station}&unit={unit}",
        //風速風向測站
        windstation : "https://lab6.eztrust.tw/api/WindStation",
        //風速風向資料
        winddata : "https://lab6.eztrust.tw/api/WindData?id={id}",
        //瀏覽人數
        browsercount:"https://lab6.eztrust.tw/api/browserCount"
    },
    API_WEBLOCAL :{ //本機 api
        //env_records:"./api/testDevice_test.json",
        realtime:"https://localhost:7191/realtime/getregion/A01?limit={limit}",
        //cwb_wind:"./api/env/wind_cwb.json",
        cwb_wind:"https://localhost:7191/realtime/getCwb?limit=1000",
        env_epa:"https://localhost:7191/realtime/getEpa?limit={limit}",
        //env_wind:"./api/env/wind.json", //新增一個資料夾放此資料
        env_wind:"./cmd/wind.json",
        //env_records:"https://localhost:7191/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        env_records:"https://localhost:7191/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}",
        env_withsta_records:"https://localhost:7191/records/env_withsta_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        //env_records2:"./api/env/record2.json",
        env_records2:"https://localhost:7191/records/heat_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_micro_records:"./api/env/epa_micro_record.json",
        env_single_records:"https://localhost:7191/records/env_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_combined_records:"https://localhost:7191/records/wind_realtime/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_combined_records2:"https://localhost:7191/records/wind_realtime2/A01?stime={stime}&etime={etime}&pu={pu}",
        env_on_single_records:"https://localhost:7191/records/env_on_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_allsta_records:"https://localhost:7191/records/history_replay/A01?stime={stime}&etime={etime}",
        //env_allsta_records:"./api/env/all_station_single_sensor.json",
        //upstation:"./api/station/status.json",
        upstation:"https://localhost:7191/devices/devices_status/A01",
        download_data:"https://localhost:7191/records/download_data/A01",
        //infostation:"https://localhost:7191/info/getInfo",
        infostation:"https://localhost:7191/devices/getInfo/A01",
        info_real_sum:"https://localhost:7191/records/real_sum/A01?stime={stime}&etime={etime}",
        info_tolerate:"https://localhost:7191/records/abnormal_info/A01",
        info_ablist:"https://localhost:7191/records/abnormal_ala_info",
        update_single_device:"https://localhost:7191/devices/single_edit",
        update_batch_device:"https://localhost:7191/devices/batch_edit",
        single_device_rules:"https://localhost:7191/devices/single_device_rules?id={id}",
        cwbstation:"./api/station/cwb.json",
        //inforule:"./api/rule/get_rule_info.json",
        inforule:"https://localhost:7191/rules/rules_list/A01",
        setTableRules:"https://localhost:7191/rules/rules_setTableData/A01",
        delTableRules:"https://localhost:7191/rules/rules_delTableData/A01",
        nearest_epa:"https://localhost:7191/station/get_nearest/epa",
        nearest_cwb:"https://localhost:7191/station/get_nearest/cwb",
        cus_realtime:"./api/customization/realtime.json",
        rules_simple_list:"https://localhost:7191/rules/rules_simple_list/A01",
        //event_com:"./api/event/complete.json",
        //event_com_all:"https://localhost:7191/devices/warn_info_all?id={id}&rules_num={rules_num}",
        event_com_all:"https://localhost:7191/devices/warn_info_all",
        event_com:"https://localhost:7191/devices/warn_info_his?id={id}&rules_num={rules_num}",
        event_com2:"https://localhost:7191/devices/warn_info_his2?id={id}&rules_num={rules_num}&stime={stime}&etime={etime}",
        event_com_details:"https://localhost:7191/devices/warn_info_his_detailes?warnId={warnId}&stime={stime}&etime={etime}",
        //瀏覽人數
        browsercount:"https://taichungiot.greenideas.com.tw/api/browserCount",
        //會員
        webRootUrl:"http://localhost:8080/",
        memLogin:"http://localhost:8080/inlopage",
        memallExe:"https://localhost:7191/Memall/exe_login",
        testCookie:"https://localhost:7191/Member/SetCookie",
        exeAllIslog:"https://localhost:7191/Memall/is_login",
        exeAuthMem:"https://localhost:7191/Memall/exe_auth",
        exeAuthUrl:"https://localhost:7191/Memall/exe_auth_url",
        memLogout:"https://localhost:7191/Memall/exe_logout",
        //line 通知
        sendLineNotify:"https://localhost:7191/LineNotify/warm_check_send"
    },
    API:{ //本機 api
        //env_records:"./api/testDevice_test.json",
        realtime:"https://lab4.eztrust.tw/api/realtime/getregion/A01?limit={limit}",
        //cwb_wind:"./api/env/wind_cwb.json",
        cwb_wind:"https://lab4.eztrust.tw/api/realtime/getCwb?limit=1000",
        env_epa:"https://lab4.eztrust.tw/api/realtime/getEpa?limit={limit}",
        //env_wind:"./api/env/wind.json", //新增一個資料夾放此資料
        env_wind:"./cmd/wind.json",
        //env_records:"https://localhost:7191/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        env_records:"https://lab4.eztrust.tw/api/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}",
        env_withsta_records:"https://lab4.eztrust.tw/api/records/env_withsta_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        //env_records2:"./api/env/record2.json",
        env_records2:"https://lab4.eztrust.tw/api/records/heat_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_micro_records:"./api/env/epa_micro_record.json",
        env_single_records:"https://lab4.eztrust.tw/api/records/env_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_combined_records:"https://lab4.eztrust.tw/api/records/wind_realtime/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_combined_records2:"https://lab4.eztrust.tw/api/records/wind_realtime2/A01?stime={stime}&etime={etime}&pu={pu}",
        env_on_single_records:"https://lab4.eztrust.tw/api/records/env_on_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_allsta_records:"https://lab4.eztrust.tw/api/records/history_replay/A01?stime={stime}&etime={etime}",
        //env_allsta_records:"./api/env/all_station_single_sensor.json",
        //upstation:"./api/station/status.json",
        upstation:"https://lab4.eztrust.tw/api/devices/devices_status/A01",
        download_data:"https://lab4.eztrust.tw/api/records/download_data/A01",
        //infostation:"https://localhost:7191/info/getInfo",
        infostation:"https://lab4.eztrust.tw/api/devices/getInfo/A01",
        info_real_sum:"https://lab4.eztrust.tw/api/records/real_sum/A01?stime={stime}&etime={etime}",
        info_tolerate:"https://lab4.eztrust.tw/api/records/abnormal_info/A01",
        info_ablist:"https://lab4.eztrust.tw/api/records/abnormal_ala_info",
        update_single_device:"https://lab4.eztrust.tw/api/devices/single_edit",
        update_batch_device:"https://lab4.eztrust.tw/api/devices/batch_edit",
        single_device_rules:"https://lab4.eztrust.tw/api/devices/single_device_rules?id={id}",
        cwbstation:"./api/station/cwb.json",
        //inforule:"./api/rule/get_rule_info.json",
        inforule:"https://lab4.eztrust.tw/api/rules/rules_list/A01",
        setTableRules:"https://lab4.eztrust.tw/api/rules/rules_setTableData/A01",
        delTableRules:"https://lab4.eztrust.tw/api/rules/rules_delTableData/A01",
        nearest_epa:"https://lab4.eztrust.tw/api/station/get_nearest/epa",
        nearest_cwb:"https://lab4.eztrust.tw/api/station/get_nearest/cwb",
        cus_realtime:"./api/customization/realtime.json",
        rules_simple_list:"https://localhost:7191/rules/rules_simple_list/A01",
        //event_com:"./api/event/complete.json",
        //event_com_all:"https://localhost:7191/devices/warn_info_all?id={id}&rules_num={rules_num}",
        event_com_all:"https://lab4.eztrust.tw/api/devices/warn_info_all",
        event_com:"https://lab4.eztrust.tw/api/devices/warn_info_his?id={id}&rules_num={rules_num}",
        event_com2:"https://lab4.eztrust.tw/api/devices/warn_info_his2?id={id}&rules_num={rules_num}&stime={stime}&etime={etime}",
        event_com_details:"https://lab4.eztrust.tw/api/devices/warn_info_his_detailes?warnId={warnId}&stime={stime}&etime={etime}",
        //瀏覽人數
        browsercount:"https://taichungiot.greenideas.com.tw/api/browserCount",
        //會員
        //memberLogin:"https://lab4.eztrust.tw/api/Member/login",
        webRootUrl:"https://lab4.eztrust.tw/",
        memLogin:"https://lab4.eztrust.tw/inlopage",
        memallExe:"https://lab4.eztrust.tw/api/Memall/exe_login",
        testCookie:"https://lab4.eztrust.tw/api/Member/SetCookie",
        exeAllIslog:"https://lab4.eztrust.tw/api/Memall/is_login",
        exeAuthMem:"https://lab4.eztrust.tw/api/Memall/exe_auth",
        exeAuthUrl:"https://lab4.eztrust.tw/api/Memall/exe_auth_url",
        memLogout:"https://lab4.eztrust.tw/api/Memall/exe_logout",
        //https://localhost:7191/Memall/exe_logout
        //line 通知
        sendLineNotify:"https://lab4.eztrust.tw/api/LineNotify/warm_check_send"
    },
    API__ :{ //正式區 api
        //env_records:"./api/testDevice_test.json",
        realtime:"https://lab.ep.ezcs.com.tw/api/realtime/getregion/A01?limit={limit}",
        //cwb_wind:"./api/env/wind_cwb.json",
        cwb_wind:"https://lab.ep.ezcs.com.tw/api/realtime/getCwb?limit=1000",
        env_epa:"https://lab.ep.ezcs.com.tw/api/realtime/getEpa?limit={limit}",
        //env_wind:"./api/env/wind.json", //新增一個資料夾放此資料
        //env_wind:"https://lab.ep.ezcs.com.tw/cus_api/wind.json",
        env_wind:"https://lab.ep.ezcs.com.tw/cmd/wind.json",
        //env_wind:"https://lab.ep.ezcs.com.tw/cmd/test_two.json",
        //env_wind_u:"https://lab.ep.ezcs.com.tw/cmd/test_u.json",
        //env_wind_v:"https://lab.ep.ezcs.com.tw/cmd/test_v.json",
        //gfs88
        //env_records:"https://lab.ep.ezcs.com.tw/api/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        env_records:"https://lab.ep.ezcs.com.tw/api/records/env_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}",
        env_withsta_records:"https://lab.ep.ezcs.com.tw/api/records/env_withsta_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}&unit={unit}&station_area={station_area}",
        //env_records2:"./api/env/record2.json",
        env_records2:"https://lab.ep.ezcs.com.tw/api/records/heat_records/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_micro_records:"./api/env/epa_micro_record.json",
        env_single_records:"https://lab.ep.ezcs.com.tw/api/records/env_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_combined_records:"https://lab.ep.ezcs.com.tw/api/records/wind_realtime/A01?stime={stime}&etime={etime}&pu={pu}&station={station}",
        env_combined_records2:"https://lab.ep.ezcs.com.tw/api/records/wind_realtime2/A01?stime={stime}&etime={etime}&pu={pu}",
        env_on_single_records:"https://lab.ep.ezcs.com.tw/api/records/env_on_single_records/A01?stime={stime}&etime={etime}&station={station}",
        env_allsta_records:"https://lab.ep.ezcs.com.tw/api/records/history_replay/A01?stime={stime}&etime={etime}",
        //env_allsta_records:"./api/env/all_station_single_sensor.json",
        //upstation:"./api/station/status.json",
        upstation:"https://lab.ep.ezcs.com.tw/api/devices/devices_status/A01",
        download_data:"https://lab.ep.ezcs.com.tw/api/records/download_data/A01",
        //infostation:"https://localhost:7191/info/getInfo",
        infostation:"https://lab.ep.ezcs.com.tw/api/devices/getInfo/A01",
        info_real_sum:"https://lab.ep.ezcs.com.tw/api/records/real_sum/A01?stime={stime}&etime={etime}",
        info_tolerate:"https://lab.ep.ezcs.com.tw/api/records/abnormal_info/A01",
        info_ablist:"https://lab.ep.ezcs.com.tw/api/records/abnormal_ala_info",
        update_single_device:"https://lab.ep.ezcs.com.tw/api/devices/single_edit",
        update_batch_device:"https://lab.ep.ezcs.com.tw/api/devices/batch_edit",
        single_device_rules:"https://lab.ep.ezcs.com.tw/api/devices/single_device_rules?id={id}",
        cwbstation:"./api/station/cwb.json",
        //inforule:"./api/rule/get_rule_info.json",
        inforule:"https://lab.ep.ezcs.com.tw/api/rules/rules_list/A01",
        setTableRules:"https://lab.ep.ezcs.com.tw/api/rules/rules_setTableData/A01",
        delTableRules:"https://lab.ep.ezcs.com.tw/api/rules/rules_delTableData/A01",
        nearest_epa:"https://lab.ep.ezcs.com.tw/api/station/get_nearest/epa",
        nearest_cwb:"https://lab.ep.ezcs.com.tw/api/station/get_nearest/cwb",
        //cus_realtime:"./api/customization/realtime.json",
        cus_realtime:"https://lab.ep.ezcs.com.tw/cus_api/realtime.json",
        rules_simple_list:"https://lab.ep.ezcs.com.tw/api/rules/rules_simple_list/A01",
        //event_com:"./api/event/complete.json",
        //event_com_all:"https://localhost:7191/devices/warn_info_all?id={id}&rules_num={rules_num}",
        event_com_all:"https://lab.ep.ezcs.com.tw/api/devices/warn_info_all",
        event_com:"https://lab.ep.ezcs.com.tw/api/devices/warn_info_his?id={id}&rules_num={rules_num}",
        event_com2:"https://lab.ep.ezcs.com.tw/api/devices/warn_info_his2?id={id}&rules_num={rules_num}&stime={stime}&etime={etime}",
        event_com_details:"https://lab.ep.ezcs.com.tw/api/devices/warn_info_his_detailes?warnId={warnId}&stime={stime}&etime={etime}",
    },
    API_real :{ // api
        realtime:"./api/env/realtime.json",
        cwb_wind:"./api/env/wind_cwb.json",
        env_epa:"./api/env/epa.json",
        env_wind:"./api/env/wind.json",
        env_records:"./api/env/record.json",
        env_records2:"./api/env/record2.json",
        env_micro_records:"./api/env/epa_micro_record.json",
        env_single_records:"./api/env/single_station_all_sensor.json",
        env_combined_records:"./api/env/combinedrecord.json",
        env_allsta_records:"./api/env/all_station_single_sensor.json",
        upstation:"./api/station/status.json",
        infostation:"./api/station/info.json",
        cwbstation:"./api/station/cwb.json",
        inforule:"./api/rule/get_rule_info.json",
        nearest_epa:"./api/station/get_nearest_epa.json",
        nearest_cwb:"./api/station/get_nearest_cwb.json",
        cus_realtime:"./api/customization/realtime.json",
        event_com:"./api/event/complete.json",
        //AQI : {limit:1000}
        aqx_p_432 : "https://taichungiot.greenideas.com.tw/api/aqx_p_432?limit={limit}", 
        //紫外線 : {limit:1000}
        uv_s_01 : "https://taichungiot.greenideas.com.tw/api/ApiUV?limit={limit}",
        //測站 api (行政區、路名)
        station : "https://taichungiot.greenideas.com.tw/api/station",
        //機器 + 時間
        records : "https://taichungiot.greenideas.com.tw/api/records?station={station}&unit={unit}",
        //風速風向測站
        windstation : "https://taichungiot.greenideas.com.tw/api/WindStation",
        //風速風向資料
        winddata : "https://taichungiot.greenideas.com.tw/api/WindData?id={id}",
        //瀏覽人數
        browsercount:"https://taichungiot.greenideas.com.tw/api/browserCount"
    },

    geturl : (str, param)=>{
        var s=str;
        for (const [key, value] of Object.entries(param)) {
            s=s.replace("{"+key+"}", value);
        }
        return s;
    },
    chkPm_val:(val)=>{
        var color = "";
        var val_float = parseFloat(val);
        if(val_float <= 15.4){
            color="green";
        }else if(val_float > 15.4 && val_float <= 35.4){
            color="yellow";
        }else if(val_float > 35.4 && val_float <= 54.4){
            color="orange";
        }else if(val_float > 54.4 && val_float <= 150.4){
            color="red";
        }else if(val_float > 150.4 && val_float <= 250.4){
            color="purple";
        }else{
            color="darkred";
        }
        return color;
    }
}