import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: 'console',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('@/views/dashboard/Dashboard.vue'),
  },
  {
    path: '/console',
    name: 'console',
    component: () => import('@/views/console/Console.vue'),
  },
  {
    path: '/console_test',
    name: 'console_test',
    component: () => import('@/views/console/Console_test.vue'),
  },
  {
    path: '/map',
    name: 'map',
    component: () => import('@/views/map/Map.vue'),
  },
  {
    path: '/analysis',
    name: 'analysis',
    component: () => import('@/views/analysis/Analysis.vue'),
  },
  {
    path: '/history',
    name: 'history',
    component: () => import('@/views/history/History.vue'),
  },
  {
    path: '/trace',
    name: 'trace',
    component: () => import('@/views/trace/Trace.vue'),
  },
  {
    path: '/list',
    name: 'list',
    component: () => import('@/views/list/List.vue'),
  },
  {
    path: '/inlopage',
    name: 'inlopage',
    component: () => import('@/views/inlopage/inlopage.vue'),
  },
  {
    path: '/status',
    name: 'status',
    component: () => import('@/views/status/Status.vue'),
  },
  {
    path: '/info',
    name: 'info',
    component: () => import('@/views/info/Info.vue'),
  },
  {
    path: '/rule',
    name: 'rule',
    component: () => import('@/views/rule/Rule.vue'),
    
  },
  {
    path: '/device',
    name: 'device',
    component: () => import('@/views/device/Device.vue'),
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

export default router
