<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-5 nav-menu-brand-area">
      <router-link
        to="/"
        class="d-flex align-center text-decoration-none"
      >
        <v-img
            class="logo me-3"
            max-height="30px"
            max-width="30px"
            alt="logo"
            contain
            eager
            src="@/assets/images/all/logo.svg"
          ></v-img>
        <v-slide-x-transition>
          <h2 class="app-title nav-menu-brand">
            台中市政府環保局
          </h2>
        </v-slide-x-transition>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list
      expand
      shaped
      class="vertical-nav-menu-items pr-5 navmenu-vv"
    >
    <!--
      <nav-menu-link
        title="Dashboard"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiHomeOutline"
      ></nav-menu-link>
      <nav-menu-link
        title="Account Settings"
        :to="{ name: 'pages-account-settings'}"
        :icon="icons.mdiAccountCogOutline"
      ></nav-menu-link>
      <nav-menu-group
        title="Pages"
        :icon="icons.mdiFileOutline"
      >
        <nav-menu-link
          title="Login"
          :to="{ name: 'pages-login' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Register"
          :to="{ name: 'pages-register' }"
          target="_blank"
        ></nav-menu-link>
        <nav-menu-link
          title="Error"
          :to="{ name: 'error-404' }"
          target="_blank"
        ></nav-menu-link>
      </nav-menu-group>
      <nav-menu-section-title title="USER INTERFACE"></nav-menu-section-title>
-->
      <nav-menu-link
        title="戰情室"
        :to="{ name: 'console' }"
        :icon="icons.mdiHomeOutline"
        data-id="1"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(1)"
      ></nav-menu-link>
      <nav-menu-link
        title="地圖"
        :to="{ name: 'map' }"
        :icon="icons.mdiGoogleMaps"
        data-id="2"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(2)"
      ></nav-menu-link>
      <nav-menu-link
        title="分析"
        :to="{ name: 'analysis' }"
        :icon="icons.mdiPoll"
        data-id="3"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(3)"
      ></nav-menu-link>
      <nav-menu-link
        title="重播"
        :to="{ name: 'history' }"
        :icon="icons.mdiPlayCircle"
        data-id="4"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(4)"
      ></nav-menu-link>
      <nav-menu-link
        title="儀錶板"
        :to="{ name: 'dashboard' }"
        :icon="icons.mdiViewDashboard"
        data-id="5"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(5)"
      ></nav-menu-link>
      <nav-menu-link
        title="事件簿"
        :to="{ name: 'trace' }"
        :icon="icons.mdiBookOpenPageVariant"
        data-id="6"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(6)"
      ></nav-menu-link>
      <nav-menu-link
        title="即時數據"
        :to="{ name: 'list' }"
        :icon="icons.mdiDatabaseClock"
        data-id="7"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(7)"
      ></nav-menu-link>
      <nav-menu-link
        title="上傳狀況"
        :to="{ name: 'status' }"
        :icon="icons.mdiCloudUpload"
        data-id="8"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(8)"
      ></nav-menu-link>
      <nav-menu-link
        title="屬性表"
        :to="{ name: 'info' }"
        :icon="icons.mdiFormatListNumbered"
        data-id="9"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(9)"
      ></nav-menu-link>
      <nav-menu-link
        title="規則管理"
        :to="{ name: 'rule' }"
        :icon="icons.mdiPencilRuler"
        data-id="10"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(10)"
      ></nav-menu-link>
      <nav-menu-link
        title="裝置管理"
        :to="{ name: 'device' }"
        :icon="icons.mdiCellphoneLink"
        data-id="11"
        v-if="nav_arry_str.length > 0 && nav_arry_str.includes(11)"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>

</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiGoogleMaps,
  mdiPoll,
  mdiPlayCircle,
  mdiFileOutline,
  mdiViewDashboard,
  mdiBookOpenPageVariant,
  mdiCloudUpload,
  mdiFormatListNumbered,
  mdiPencilRuler,
  mdiCellphoneLink,
  mdiAccountCogOutline,
} from '@mdi/js'
import NavMenuSectionTitle from './components/NavMenuSectionTitle.vue'
import NavMenuGroup from './components/NavMenuGroup.vue'
import NavMenuLink from './components/NavMenuLink.vue'
import axios from 'axios';
import config from '@/config.js';


export default {
  components: {
    NavMenuSectionTitle,
    NavMenuGroup,
    NavMenuLink,
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return{
      nav_arry_str:[]
    }
  },
  setup() {
    
    return {
            icons: {
              mdiHomeOutline,
              mdiGoogleMaps,
              mdiPoll,
              mdiPlayCircle,
              mdiFileOutline,
              mdiViewDashboard,
              mdiBookOpenPageVariant,
              mdiCloudUpload,
              mdiFormatListNumbered,
              mdiPencilRuler,
              mdiCellphoneLink,
              mdiAccountCogOutline,
            }
          }
  },
  created(){
    let self = this;
    //檢查會員權限
    const apiurl_authMem = config.geturl(config.API.exeAuthMem,{});
    axios.get(apiurl_authMem).then(response => {
          //console.log(response.data);

          
          if(response.data.isOk){
            let nav_str_ay = response.data.log.split(',');
            //測試先拿掉
            nav_str_ay.forEach(function(item){
               self.nav_arry_str.push(parseInt(item));
            });
          }
    });
  }
}
</script>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
