<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <v-app-bar
      app
      flat
      absolute
      color="transparent"
      class="header_main"
    >
      <div class="boxed-container w-full">
        <div class="d-flex align-center mx-md-6 mx-3">
          <!-- Left Content -->
          <div class="d-block me-2 header-nav-menu-button-area">
            <a href="javascript:void(0);"
            class="d-block me-2 btn-toggle-side-menu"
            :class="{open:isDrawerOpen}"
            @click="isDrawerOpen = !isDrawerOpen"
            >
              <div class="icon"></div>
            </a>
          </div>

          <h1 class="nav_mainTitle"></h1>

          <!--<v-text-field
            rounded
            dense
            outlined
            :prepend-inner-icon="icons.mdiMagnify"
            class="app-bar-search flex-grow-0"
            hide-details
          ></v-text-field>-->

          <v-spacer></v-spacer>



          <v-btn
             color="primary"
            class="ms-3"
            @click="send_line_notify"
          >
            測試發送 line 通知
          </v-btn>
          <v-btn
            icon
            small
            class="ms-3 btn-gray"
          >
            <v-icon>
              {{ icons.mdiBellOutline }}
            </v-icon>
          </v-btn>
          <app-bar-user-menu></app-bar-user-menu>
        </div>
      </div>
    </v-app-bar>

    <v-main>
      <div class="app-content-container boxed-container pa-6 pt-5">
        <slot></slot>
      </div>
    </v-main>

  </v-app>
</template>

<script>
import { ref } from '@vue/composition-api'
import { mdiMagnify, mdiBellOutline } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'
import AppBarUserMenu from './components/AppBarUserMenu.vue'
import config from '@/config.js';
import axios from 'axios';


export default {
  components: {
    VerticalNavMenu,
    AppBarUserMenu,
  },
  setup() {
    const isDrawerOpen = ref(false)

    return {
      isDrawerOpen,

      // Icons
      icons: {
        mdiMagnify,
        mdiBellOutline,
      },
    }
  },
  methods:{
    send_line_notify(){
      const apiurl=config.geturl(config.API.sendLineNotify,{});
      axios.get(apiurl)
        .then((response) => {
          if(response.data.status == "N"){
              alert(response.data.log);
            }else{
              alert("發送成功");
            }
      })
    }
  }
}
</script>

<style lang="scss" scoped>

.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
