<template>
  <component :is="resolveLayout">
    <router-view></router-view>
  </component>
</template>

<script>
import { computed } from '@vue/composition-api'
import { useRouter } from '@/utils'
import LayoutBlank from '@/layouts/Blank.vue'
import LayoutContent from '@/layouts/Content.vue'
import axios from 'axios';
import config from '@/config.js';

let route_name="";

export default {
  components: {
    LayoutBlank,
    LayoutContent,
  },
  setup() {
    const { route } = useRouter()

    const resolveLayout = computed(() => {
      // Handles initial route
      if (route.value.name === null) return null

      if (route.value.meta.layout === 'blank') return 'layout-blank'
      route_name = route.value.name;
      // console.log(route_name);
      
      // 檢查登入
      const apiurl_islogin = config.geturl(config.API.exeAllIslog,{});
      const mem_login_url = config.geturl(config.API.memLogin,{});
      if(route.value.name != "inlopage"){
          axios.get(apiurl_islogin).then(response => {
            if(response.data !== ""){
              //alert("登入失敗");
              location.href=mem_login_url;
            }else{
              //進行權限檢查
              //alert("登入成功");
            }
        })
      }

      return 'layout-content'
    })

    return {
      resolveLayout,
    }
  }
}
</script>
